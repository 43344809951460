:root {
  --background: black;
  --color: white;
  --padding: 10px;
  background: black;
  color: white;
}

body {
  margin: 0;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

button:disabled,
button:disabled:hover {
  box-shadow: var(--box-shadow, 0 0 0 0 currentColor);
  transform: var(--transform, translate(0, 0));
}

.bam {
  animation: bam 400ms 1;
}

.boom {
  animation: boom 400ms 1;
}

@keyframes bam {
  0% {
    background: white;
  }
  25% {
    background: black;
  }

  50% {
    background: white;
  }
  100% {
    background: black;
  }
}

@keyframes boom {
  0% {
    background: white;
  }
  25% {
    background: black;
  }

  50% {
    background: white;
  }
  100% {
    background: black;
  }
}

.hover {
  animation: hover 4000ms infinite;
}

@keyframes hover {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
